.BestAvailableWidget {
    pointer-events: auto;
    cursor: pointer;
    .horizontal-flex;
    justify-content: center;
    width: 220px;
    border-radius: 16px;
    color: black;
    background: hsla(0, 0%, 95%, 0.15);
    transform: translate3d(0, 0, 0);
    height: 2em;

    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 97%);
        background: hsla(0, 0%, 0%, 0.25);
    }

    > .icon {
        display: none;
    }

    > .label {
        pointer-events: none;
        text-align: center;
        position: relative;
        top: -1px;
    }
}

.toolbar > .contents > .BestAvailableWidget {
    transition: all 0.3s @ease-out-quint;
    box-shadow: 0 4px 12px hsla(240, 4%, 5%, 0.1);
    
    &:hover {
        background: hsla(0, 0%, 100%, 0.4);
        box-shadow: inset 0 -5px 15px hsla(0, 0%, 100%, 0.3);
    }

    &:active,
    &.selected {
        color: black;
        background: white;
        transition-duration: 0.1s;

        .label {
            opacity: 1;
        }
    }

    @media (prefers-color-scheme: dark) {
        box-shadow: 0 0 16px hsla(240, 4%, 100%, 0.1);
        &:hover {
            background: hsla(0, 0%, 0%, 0.3);
            box-shadow: inset 0 -5px 15px hsla(0, 0%, 0%, 0.3);
        }
        &:active,
        &.selected {
            color: rgb(255, 255, 255);
            background: hsla(0, 0%, 0%, 0.5);
        }
    }
}
