.ContextualMenu {
    pointer-events: none;
    cursor: default;
    position: fixed;
    .zero-top-right-bottom-left;
    z-index: 2000;
    background-color: hsla(0, 0%, 0%, 0.1);
    opacity: 0;
    transition: opacity 0.15s @ease-out-quint;

    > .menu {
        position: fixed;
        .vertical-flex;
        padding: 5px 0;
        background: rgb(250, 250, 250);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-shadow:
            0 0 0 1px rgba(0, 0, 0, .05),
            0 8px 22px rgba(0, 0, 0, .2),
            inset 0 1px 0 1px rgba(255, 255, 255, .25);
        animation: slight-zoom-in 0.1s @ease-out-cubic;

        @media (prefers-color-scheme: dark) {
            background: hsla(0, 0%, 15%, 0.99);
            box-shadow:
                0 0 0 1px rgba(0, 0, 0, 0.05),
                0 8px 22px rgba(20, 20, 20, 0.2),
                inset 0 0 0 1px rgba(255, 255, 255, 0.05);
        }

        > .title {
            pointer-events: none;
            width: 100%;
            height: 22px;
            box-sizing: border-box;
            color: #999;
            padding-left: 25px;
            padding-top: 3px;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
        }

        > .separator {
            width: 100%;
            height: 1px;
            background: rgb(226, 226, 226);
            margin-top: 5px;
            margin-bottom: 5px;
        }

        > .validation-item {
            padding: 5px 1.5em 5px 1em;
            width: 100%;
            box-sizing: border-box;
            font-size: 13px;

            &.warning {
                color: @color-alert-warning-deep;
            }

            &.error {
                color: @color-alert-danger;
            }

            > .icon {
                margin-right: 0.5em;
                font-size: 15px;
            }

            > .caption {
                position: relative;
                top: -1px;
            }
        }
    }

    &.open {
        pointer-events: auto;
        opacity: 1;
    }

    @media @query-mobile {
        .vertical-flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 5px;
        padding-top: @top-panel-mobile-height + 5px;
        background-color: hsla(0, 0%, 0%, 0.5);
        transition-duration: 0.3s;

        > .menu {
            min-width: 30vw;
            position: static;
            border-radius: 10px;
            opacity: 0;
            transition: opacity 0.5s @ease-out-quint;

            .Button {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 17px !important;

                .caption {
                    font-size: inherit;
                }
                .softIcon {
                    font-size: inherit !important;
                }
            }
        }

        &.open {
            > .menu {
                animation: contextual-menu-mobile-in 0.3s @ease-out-quint backwards;
                opacity: 1;
            }
        }
    }
}

@keyframes contextual-menu-in {
    from {
        background-color: hsla(0, 0%, 0%, 0);
    }
}

@keyframes contextual-menu-mobile-in {
    from {
        transform: translateY(-20px);
    }
}

