.MyTicketsDialog {
    pointer-events: none;
    position: absolute;
    .zero-top-right-bottom-left;
    z-index: 2000;
    background-color: hsla(0, 0%, 0%, 0.1);
    transition: opacity 0.1s @ease-out-quint;

    > .close-button {
        position: absolute;
        left: 10px;
        top: 5px;
        display: none;
        padding: 15px;
        font-size: 24px;
        opacity: 0;
        transform: scale(0);
        transition: all 0.4s @ease-out-quint;

        @media (prefers-color-scheme: dark) {
            color: white;
        }
    }

    > .dialog {
        pointer-events: inherit;
        position: absolute;
        background: white;
        width: 220px;
        border-radius: 16px;
        transition: border-radius 0.1s @ease-out-quint;
        box-shadow: 0 8px 40px hsla(0, 0%, 0%, 0.2);

        @keyframes ticket-dialog-bounce-in {
            from {
                transform: translateY(5px);
                box-shadow:
                    0 0 20px hsla(0, 0%, 100%, 0),
                    0 8px 40px hsla(0, 0%, 0%, 0);
            }
            20% {
                transform: translateY(10px);
                box-shadow:
                    0 0 20px hsla(0, 0%, 100%, 0.3),
                    0 8px 40px hsla(0, 0%, 0%, 0.1);
            }
            60% {
                transform: translateY(-2px);
                box-shadow:
                    0 0 20px hsla(0, 0%, 100%, 0.2),
                    0 8px 40px hsla(0, 0%, 0%, 0.2);
            }
            to {
                transform: translateY(0px);
                box-shadow:
                    0 0 20px hsla(0, 0%, 100%, 0),
                    0 8px 40px hsla(0, 0%, 0%, 0.2);
            }
        }

        > .MyTicketsWidget {
            pointer-events: inherit;
            height: 40px;

            &,
            &:hover,
            &:active {
                background: none;
                color: inherit;
            }
        }

        > .notice {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            text-align: center;
            color: #999;
        }

        .category {
            padding: 0 20px;
            margin-top: 20px;
            margin-bottom: 2px;
            font-size: 14px;
            font-weight: bold;
        }
    
        > .button {
            cursor: pointer;
            width: 200px;
            padding: 10px 0;
            margin-left: 10px;
            margin-bottom: 10px;
            margin-top: 15px;
            background: @color-selected;
            border-radius: 10px;
            box-shadow:
                0 6px 6px hsla(209, 96%, 50%, 0.4),
                inset 0 -3px 4px hsla(197, 99%, 63%, 0.4);
            color: white;
            text-align: center;
            font-weight: bold;
            transition: all 0.2s @ease-out-quint;

            &:hover {
                background: @color-selected-highlight;
                box-shadow:
                    0 6px 6px hsla(194, 96%, 50%, 0.4),
                    inset 0 -3px 4px hsla(197, 99%, 63%, 0.4);
            }

            &:active {
                background: @color-selected-press;
                box-shadow:
                    0 6px 6px hsla(209, 99%, 46%, 0.4),
                    inset 0 -3px 4px hsla(197, 99%, 63%, 0.6);
            }
        }
    
        .TicketItem {
            padding: 10px 20px;
    
            .label {
                color: #333;
                font-size: 15px;
                font-weight: 500;
            }
    
            .quantity {
                font-size: 15px;
                color: #333;

                &::before {
                    content: "✕ ";
                    font-size: 13px;
                }
            }
    
            & + .TicketItem {
                border-top: 1px solid #eee;
            }
        }
    }

    &:not(.open) {
        opacity: 0;

        > .dialog {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &.open {
        pointer-events: auto;
        opacity: 1;

        > .close-button {
            transform: scale(1);
            transition-delay: 0.3s;
            opacity: 1;
        }

        > .dialog {
            animation: ticket-dialog-bounce-in 0.4s @ease-out-quint;
        }
    }

    @media @query-mobile {
        .vertical-flex;
        justify-content: center;
        transition-duration: 0.3s;

        .close-button {
            display: block;
        }

        > .dialog {
            position: static;
            margin-top: -40px;
        }

        &.open {
            > .dialog {
                animation: ticket-dialog-slide-in 0.8s @ease-out-quint;
            }
        }
    }

    @keyframes ticket-dialog-slide-in {
        from {
            transform: translateY(50vh);
        }
    }
}