.Button {
    position: relative;
    font-size: 14px;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    outline: none !important;

    .icon, .caption, img {
        pointer-events: none;
    }

    &.preset-contextual-menu-item {
        width: 100%;
        padding: 3px 20px 3px 16px;
        color: black;
        text-align: left;

        @media (prefers-color-scheme: dark) {
            color: hsl(0, 0%, 97%);
        }

        > .wrapper {
            display: block;
            .horizontal-flex;
            align-items: center;

            > .icon {
                display: block;
                margin-right: 8px;
                font-size: 19px;
            }
            > .caption {
                display: block;
                font-size: 15px;
                padding-bottom: 1px;
                font-weight: 500;
            }
        }

        @media @query-desktop {
            &:hover:not(:disabled) {
                color: white;
                background: @color-selected;
            }
        }

        &:active:not(:disabled) {
            color: white;
            background: @color-selected-deep;
        }

        &:disabled {
            color: grey;
        }

        .wrapper.with-soft-icon {
            position: relative;
            padding-right: 2em;

            .softIcon {
                position: absolute;
                right: -0.5em;
                opacity: 0.5;
                font-size: 0.9em;
            }
        }
    }
}
