.MyTicketsWidget {
    pointer-events: auto;
    cursor: pointer;
    .horizontal-flex;
    justify-content: center;
    width: 220px;
    border-radius: 16px;
    color: black;
    background: hsla(0, 0%, 95%, 0.15);
    transform: translate3d(0, 0, 0);

    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 97%);
        background: hsla(0, 0%, 0%, 0.25);
    }

    > .icon {
        display: none;
    }

    > .label {
        pointer-events: none;
        text-align: center;
        position: relative;
        top: -1px;
    }
    
    > .ticket-count {
        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -0.75em;
        min-width: 1.5em;
        height: 1.5em;
        padding: 3px;
        box-sizing: border-box;
        background: hsl(212, 100%, 50%);
        border-radius: 3em;
        font-weight: bold;
        line-height: 1.1em;
        text-align: center;
        color: white;
        box-shadow: 0 2px 4px hsla(212, 100%, 50%, 0.3);
        transition: opacity 0.3s @ease-out-quint;

        &::after {
            content: "";
            display: block;
            .full-size-absolute;
            border-radius: inherit;
        }

        &.visible {
            opacity: 1;
        }

        &.hidden {
            opacity: 0;
        }
    }

    &.pulse {
        > .ticket-count {
            animation: ticket-count-pulse-gulp 0.9s @ease-out-quint 0.1s;

            &::after {
                animation-name: ticket-count-pulse-ring;
                animation-duration: 0.8s;
                animation-timing-function: @ease-out-quint;
            }
        }

        &.outwards {
            > .ticket-count {
                animation: ticket-count-pulse-shrink 0.8s @ease-out-quint;

                &::after {
                    animation-timing-function: @ease-in-quint;
                    animation-direction: reverse;
                }
            }
        }

        &.soft {
            > .ticket-count {
                animation-duration: 0.8s;
                animation-delay: 0;

                &::after {
                    animation: ticket-count-pulse-ring-soft 0.9s @ease-out-quint;
                }
            }
        }
    }

    @keyframes ticket-count-pulse-gulp {
        from, to {
            transform: scale(1);
            filter: brightness(1);
        }
        20% {
            transform: scale(1.15);
            filter: brightness(1.5);
        }
    }

    @keyframes ticket-count-pulse-shrink {
        from, to {
            transform: scale(1);
            filter: brightness(1);
        }
        20% {
            transform: scale(0.9);
            filter: brightness(0.9);
        }
    }

    @keyframes ticket-count-pulse-ring {
        from {
            box-shadow: 0 0 0 50px hsla(212, 100%, 50%, 0);
        }
        to {
            box-shadow: 0 0 0 0 hsla(212, 100%, 50%, 0.5);
        }
    }

    @keyframes ticket-count-pulse-ring-soft {
        from {
            box-shadow: 0 0 0 0 hsla(212, 100%, 50%, 0.3);
        }
        to {
            box-shadow: 0 0 0 40px hsla(212, 100%, 50%, 0);
        }
    }
}

.toolbar > .contents > .MyTicketsWidget {
    transition: all 0.3s @ease-out-quint;
    box-shadow: 0 4px 12px hsla(240, 4%, 5%, 0.1);
    
    &:hover {
        background: hsla(0, 0%, 100%, 0.4);
        box-shadow: inset 0 -5px 15px hsla(0, 0%, 100%, 0.3);
    }

    &:active,
    &.selected {
        color: black;
        background: white;
        transition-duration: 0.1s;

        .label {
            opacity: 1;
        }
    }

    @media (prefers-color-scheme: dark) {
        box-shadow: 0 0 16px hsla(240, 4%, 100%, 0.1);
        &:hover {
            background: hsla(0, 0%, 0%, 0.3);
            box-shadow: inset 0 -5px 15px hsla(0, 0%, 0%, 0.3);
        }
        &:active,
        &.selected {
            color: rgb(255, 255, 255);
            background: hsla(0, 0%, 0%, 0.5);
        }
    }
}