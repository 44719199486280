.VenueView {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;

    > .frosted-backdrop {
        position: fixed;
        .zero-top-right-bottom-left;
        background-size: cover;
        background-position: @venue-background-position;
    }

    > .top-panel {
        height: @top-panel-height;
        position: relative;
        z-index: 1;

        > .background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100vw;
            height: @top-panel-height;
            background-size: cover;
            background-position: @venue-background-position;
        }

        > .toolbar {
            cursor: default;
            pointer-events: none;
            position: absolute;
            border-radius: 100px;
            height: 40px;
            left: @toolbar-spacing;
            background: white;
            box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1);
            
            > .content-clip {
                .full-size-absolute;
                overflow: hidden;
                border-radius: inherit;
            }

            &::after {
                content: "";
                display: block;
                .full-size-absolute;
                border-radius: inherit;
                box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.1);
            }

            > .contents {
                .horizontal-flex;
                height: inherit;

                .item {
                    cursor: pointer;
                    pointer-events: auto;
                    .horizontal-flex;
                    position: relative;
                    height: inherit;
                    padding: 10px 18px 10px 22px;
                    box-sizing: border-box;
                    color: hsla(0, 0%, 0%, 0.85);

                    .icon {
                        pointer-events: none;
                        font-size: 26px;
                        margin-right: 7px;
                    }

                    .label {
                        pointer-events: none;
                        opacity: 0.7;
                    }

                    &.dropdown {
                        &::after {
                            position: relative;
                            top: 2px;
                            content: "\e946";
                            font-family: 'seatsdesigner';
                            margin-left: 8px;
                        }
                    }

                    &.combination {
                        > .labels {
                            .vertical-flex;
                            align-items: flex-end;

                            > .label {
                                font-size: 0.8em;
                            }
                        }
                    }

                    @media (prefers-color-scheme: dark) {
                        color: hsla(0, 0%, 100%, 0.85);
                    }
                }

                > .MyTicketsWidget {
                    height: calc(100% - 7px);
                }

                &.breadcrumbs {
                    .item .icon {
                        padding-left: 5px;
                        font-size: 36px;
                    }

                    .item + .item::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -7px;
                        display: block;
                        width: 15px;
                        height: 40px;
                        background-image: url("../../assets/images/gui/breadcrumb-arrow-light@2x.png");
                        background-size: contain;
                        background-repeat: no-repeat;

                        @media (prefers-color-scheme: dark) {
                            background-image: url("../../assets/images/gui/breadcrumb-arrow-dark@2x.png");
                        }
                    }
                }

                &.menus {
                    .item {
                        &:hover {
                            background: hsla(0, 0%, 100%, 0.1);
                        }
                        &:active,
                        &.selected {
                            color: black;
                            background: white;

                            .label {
                                opacity: 1;
                            }
                        }

                        @media (prefers-color-scheme: dark) {
                            &:hover {
                                background: hsla(0, 0%, 0%, 0.1);
                            }
                            &:active,
                            &.selected {
                                color: rgb(255, 255, 255);
                                background: hsla(0, 0%, 0%, 0.5);
                            }
                        }
                    }
                }

                &.anchor-right {
                    .horizontal-flex;
                    position: absolute;
                    right: 15px;
                    top: 0;
                }
            }

            .frosted-glass {
                position: absolute;
                left: -@toolbar-spacing;
                width: 100vw;
                height: @top-panel-height;
                background-size: cover;
                background-position: @venue-background-position;
                filter: brightness(1.4) contrast(1.3);
                opacity: 0.3;
            }

            &.desktop-navigation {
                top: @toolbar-spacing;

                .frosted-glass {
                    top: -@toolbar-spacing;
                }
            }

            &.mobile-navigation {
                left: 0;
                display: none;
                width: 100%;

                > .contents {
                    justify-content: space-between;

                    > .item > .icon {
                        font-size: 32px;
                    }
                }
            }

            &.bottom {
                bottom: @toolbar-spacing;

                .frosted-glass {
                    bottom: -@toolbar-spacing;
                }
            }

            &.wide {
                width: 100%;
                left: 0;
                height: 44px;
                padding: 0 10px;
                box-sizing: border-box;
                border-radius: 0;

                &.bottom {
                    bottom: 0;
                }

                .frosted-glass {
                    left: 0;
                }
            }

            @media (prefers-color-scheme: dark) {
                background: black;

                .frosted-glass {
                    filter: brightness(0.7) contrast(1.2);
                    opacity: 1;
                }
            }
        }
    }

    .chart {
        position: relative;
        height: calc(100vh - @top-panel-height - 64px);

        .embed {
            position: relative;
            z-index: 1;
            height: inherit;
            @light-background: #eee;
            @dark-background: #141414;
            background: @light-background;
        
            @media (prefers-color-scheme: dark) {
                background: @dark-background;
            }

            &.light {
                background: @light-background;
            }

            &.dark {
                background: @dark-background;
            }
        }
    }

    @media @query-desktop {
        > .top-panel {
            .toolbar {
                .label.short {
                    display: none;
                }
            }
        }
    }

    @media @query-mobile {
        background: white;

        .frosted-backdrop {
            filter: brightness(1.6) contrast(1.3);
            opacity: 0.3;
        }

        > .top-panel {
            height: @top-panel-mobile-height;
            transition: 0.75s @ease-out-quint;
            transition-property: opacity, transform;

            .background,
            .frosted-glass {
                display: none;
            }
            
            .toolbar {
                background: none;
                box-shadow: none;

                .label.long {
                    display: none;
                }
                
                &.desktop-navigation,
                &::after {
                    display: none;
                }

                &.mobile-navigation {
                    display: block;
                }

                &.bottom {
                    height: auto;
                    margin-bottom: 2px;

                    .contents {
                        justify-content: flex-start;

                        .item {
                            .vertical-flex;
                            padding: 4px 0 4px;
                            height: auto;
                            width: 70px;
                            border-radius: 8px;

                            .icon {
                                font-size: 26px;
                                margin-right: 0;
                                margin-bottom: 4px;
                            }

                            .label {
                                opacity: 0.7;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .MyTicketsWidget {
                .vertical-flex;
                flex-direction: column-reverse;
                padding: 4px 0 4px;
                height: auto;
                width: 80px;
                border-radius: 8px;

                > .icon {
                    display: none;
                    font-size: 26px;
                    margin-right: 0;
                    margin-bottom: 4px;
                    visibility: hidden;
                }

                > .ticket-count {
                    position: static;
                    margin: 3px 0;

                    &.hidden {
                        opacity: 0.9;
                        background-color: hsl(0, 0%, 50%);
                    }
                }

                > .label {
                    opacity: 0.7;
                    font-size: 12px;
                }
            }
        }

        .chart {
            .embed {
                height: 100%;
                transition: 1.2s @ease-out-quint;
                transition-property: opacity, transform, filter;
                transition-delay: 0.05s;
                border-radius: 6px;
                overflow: hidden;
                background: transparent;
            }
        }

        &.openedTicketsDialog {
            .top-panel {
                transition-duration: 0.3s;
                transition-timing-function: ease-in;
                transform: translateY(-@top-panel-mobile-height);
                opacity: 0;
            }
            .embed {
                transition-delay: 0;
                transform: scale(0.9);
                filter: blur(15px);
                opacity: 0.8;
            }
        }

        @media (prefers-color-scheme: dark) {
            background: black;
    
            .frosted-backdrop {
                filter: brightness(0.7) contrast(1.2);
                opacity: 1;
            }
        }
    }
}
