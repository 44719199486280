body {
    margin: 0;
    padding: 0;
    background: #AAE5FF;
    height: 100vh;
    overflow: hidden;

    @media (prefers-color-scheme: dark) {
        background: #06161D;
    }
}

.App {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

    * {
        -webkit-user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
    }

    .switch-wrapper {
        position: relative;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }
    
    .switch-wrapper > div {
        position: absolute;
        width: 100vw;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &.transitioning {
        pointer-events: none;
    }
}