.Home {
    width: 100vw;
    min-height: 720px;
    background:
        linear-gradient(to bottom, #F248A4, #DECCDA, #AAE5FF),
        #AAE5FF;
    background-size: 100% 720px;
    background-repeat: no-repeat;
    padding-bottom: 100px;

    @media (prefers-color-scheme: dark) {
        background:
            linear-gradient(to bottom, #2A1B58, #B63196, #06161D),
            #06161D;
        background-size: 100% 720px;
        background-repeat: no-repeat;
    }

    > .hero {
        position: relative;
        .vertical-flex;
        justify-content: center;
        width: 100vw;
        height: 465px;
        padding-bottom: 55px;
        overflow: hidden;

        > .vapor-gradient {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            width: 100%;
            height: 460px;
            overflow-y: hidden;

            > .pattern {
                width: inherit;
                height: 470px;
                background-image: url('../../assets/images/home/gradient-pattern-light@2x.png');
                background-size: 2px 315px;
                background-position: center top;
                background-repeat: repeat-x;
                animation: vapor-gradient-shift 1s linear infinite;
    
                @keyframes vapor-gradient-shift {
                    from {
                        transform: translateY(0);
                        opacity: 1;
                    }
                    to {
                        transform: translateY(10px);
                        opacity: 0.8;
                    }
                }

                @media (prefers-color-scheme: dark) {
                    background-image: url('../../assets/images/home/gradient-pattern-dark@2x.png');
                }
            }
        }
            
        > .palmtree {
            position: absolute;
            z-index: 1;
            width: 5.18em;
            height: 5.05em;
            font-size: 100px;
            animation: palmtrees-enter 7s @ease-out-quint forwards;

            .image,
            .flip {
                width: inherit;
                height: inherit;
            }

            .image {
                background-image: url('../../assets/images/home/palmtree-light@2x.png');
                background-size: contain;
                animation: palmtrees-wind 14s ease-in-out infinite;
                transform-origin: bottom left;

                @media (prefers-color-scheme: dark) {
                    background-image: url('../..//assets/images/home/palmtree-dark@2x.png');
                }
            }

            &.left {
                top: 0.5em;
                left: -1em;
                transform-origin: left center;
            }
            &.right {
                top: 0.2em;
                right: -1.5em;
                transform-origin: right center;

                .flip {
                    transform: rotateY(180deg) scale(0.75) translateZ(-1px);
                }
                .image {
                    animation-delay: 2s;
                }
            }

            @keyframes palmtrees-enter {
                from {
                    transform: scale(0.3);
                }
            }

            @keyframes palmtrees-wind {
                from, 20%, 35%, 58%, 79%, to {
                    transform: rotate(0deg);
                }
                12%, 28%, 47%, 66%, 92% {
                    transform: rotate(0.3deg);
                }
            }
        }

        > .logo {
            position: relative;
            z-index: 1;
            .vertical-flex;
            font-size: 125px;
            animation: palmtrees-enter 2s @ease-out-quint forwards;
            
            > .ticket-shop {
                width: 2.66em;
                height: 1.90em;
                animation: hue-shift 10s ease-in-out infinite;
                background-image: url('../../assets/images/home/ticket-shop-light@2x.png');
                background-size: contain;

                @media (prefers-color-scheme: dark) {
                    background-image: url('../../assets/images/home/ticket-shop-dark@2x.png');
                }
            }

            > .seatsio {
                width: 2.26em;
                height: .19em;
                margin-top: 0.3em;
            }

            @keyframes hue-shift {
                from, to {
                    filter: hue-rotate(-20deg);
                }
                50% {
                    filter: hue-rotate(20deg);
                }
            }

            @media (max-width: 650px) {
                font-size: 100px;
            }

            @media (max-width: 400px) {
                font-size: 75px;
            }
        }
            
        > .island {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            z-index: 0;
            height: 2.41em;
            background-image: url('../../assets/images/home/island-light@2x.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center bottom;
            font-size: 100px;
            transform-origin: center bottom;
            animation: palmtrees-enter 12s @ease-out-quint forwards;

            @media (prefers-color-scheme: dark) {
                background-image: url('../../assets/images/home/island-dark@2x.png');
            }
        }

        @media (max-width: 650px) {
            > .palmtree {
                font-size: 50px;
            }
        }
    }

    > .venues {
        position: relative;
        z-index: 1;
        top: -50px;
        margin: 0 auto;
        max-width: 990px;

        .Venue + .Venue {
            margin-top: 50px;
        }

        @media (max-width: 990px) {
            overflow-x: hidden;
        }
    }
}
