/* Sizing */

@toolbar-spacing: 10px;
@top-panel-height: 200px;
@top-panel-mobile-height: 100px;
@venue-background-position: center 35%;


/* Positioning */

.horizontal-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.vertical-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zero-top-right-bottom-left {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.full-size-absolute {
    position: absolute;
    .zero-top-right-bottom-left;
}


/* Media queries */

@query-desktop: ~"(min-width: 600px)";
@query-mobile: ~"(max-width: 599px)";