/*** Easings ***/
/* Status colors */
/* Sizing */
/* Positioning */
.horizontal-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.vertical-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.zero-top-right-bottom-left {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.full-size-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* Media queries */
body {
  margin: 0;
  padding: 0;
  background: #AAE5FF;
  height: 100vh;
  overflow: hidden;
}
@media (prefers-color-scheme: dark) {
  body {
    background: #06161D;
  }
}
.App {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
.App * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
}
.App .switch-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.App .switch-wrapper > div {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.App.transitioning {
  pointer-events: none;
}
.SceneTransition {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
}
.SceneTransition .sheet {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(100%);
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.SceneTransition .sheet.foreground {
  background-color: #82d7e6;
  animation-delay: 0.2s;
}
.SceneTransition .sheet.background {
  background-color: #e980bd;
}
.SceneTransition.transitioning {
  pointer-events: auto;
}
.SceneTransition.transitioning .sheet {
  animation-name: sheet-transition;
}
@keyframes sheet-transition {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-120%);
  }
}
.Home {
  width: 100vw;
  min-height: 720px;
  background: linear-gradient(to bottom, #F248A4, #DECCDA, #AAE5FF), #AAE5FF;
  background-size: 100% 720px;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}
@media (prefers-color-scheme: dark) {
  .Home {
    background: linear-gradient(to bottom, #2A1B58, #B63196, #06161D), #06161D;
    background-size: 100% 720px;
    background-repeat: no-repeat;
  }
}
.Home > .hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 465px;
  padding-bottom: 55px;
  overflow: hidden;
}
.Home > .hero > .vapor-gradient {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 460px;
  overflow-y: hidden;
}
.Home > .hero > .vapor-gradient > .pattern {
  width: inherit;
  height: 470px;
  background-image: url('../assets/images/home/gradient-pattern-light@2x.png');
  background-size: 2px 315px;
  background-position: center top;
  background-repeat: repeat-x;
  animation: vapor-gradient-shift 1s linear infinite;
}
@keyframes vapor-gradient-shift {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(10px);
    opacity: 0.8;
  }
}
@media (prefers-color-scheme: dark) {
  .Home > .hero > .vapor-gradient > .pattern {
    background-image: url('../assets/images/home/gradient-pattern-dark@2x.png');
  }
}
.Home > .hero > .palmtree {
  position: absolute;
  z-index: 1;
  width: 5.18em;
  height: 5.05em;
  font-size: 100px;
  animation: palmtrees-enter 7s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}
.Home > .hero > .palmtree .image,
.Home > .hero > .palmtree .flip {
  width: inherit;
  height: inherit;
}
.Home > .hero > .palmtree .image {
  background-image: url('../assets/images/home/palmtree-light@2x.png');
  background-size: contain;
  animation: palmtrees-wind 14s ease-in-out infinite;
  transform-origin: bottom left;
}
@media (prefers-color-scheme: dark) {
  .Home > .hero > .palmtree .image {
    background-image: url('..//assets/images/home/palmtree-dark@2x.png');
  }
}
.Home > .hero > .palmtree.left {
  top: 0.5em;
  left: -1em;
  transform-origin: left center;
}
.Home > .hero > .palmtree.right {
  top: 0.2em;
  right: -1.5em;
  transform-origin: right center;
}
.Home > .hero > .palmtree.right .flip {
  transform: rotateY(180deg) scale(0.75) translateZ(-1px);
}
.Home > .hero > .palmtree.right .image {
  animation-delay: 2s;
}
@keyframes palmtrees-enter {
  from {
    transform: scale(0.3);
  }
}
@keyframes palmtrees-wind {
  from,
  20%,
  35%,
  58%,
  79%,
  to {
    transform: rotate(0deg);
  }
  12%,
  28%,
  47%,
  66%,
  92% {
    transform: rotate(0.3deg);
  }
}
.Home > .hero > .logo {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 125px;
  animation: palmtrees-enter 2s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}
.Home > .hero > .logo > .ticket-shop {
  width: 2.66em;
  height: 1.9em;
  animation: hue-shift 10s ease-in-out infinite;
  background-image: url('../assets/images/home/ticket-shop-light@2x.png');
  background-size: contain;
}
@media (prefers-color-scheme: dark) {
  .Home > .hero > .logo > .ticket-shop {
    background-image: url('../assets/images/home/ticket-shop-dark@2x.png');
  }
}
.Home > .hero > .logo > .seatsio {
  width: 2.26em;
  height: 0.19em;
  margin-top: 0.3em;
}
@keyframes hue-shift {
  from,
  to {
    filter: hue-rotate(-20deg);
  }
  50% {
    filter: hue-rotate(20deg);
  }
}
@media (max-width: 650px) {
  .Home > .hero > .logo {
    font-size: 100px;
  }
}
@media (max-width: 400px) {
  .Home > .hero > .logo {
    font-size: 75px;
  }
}
.Home > .hero > .island {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 0;
  height: 2.41em;
  background-image: url('../assets/images/home/island-light@2x.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  font-size: 100px;
  transform-origin: center bottom;
  animation: palmtrees-enter 12s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}
@media (prefers-color-scheme: dark) {
  .Home > .hero > .island {
    background-image: url('../assets/images/home/island-dark@2x.png');
  }
}
@media (max-width: 650px) {
  .Home > .hero > .palmtree {
    font-size: 50px;
  }
}
.Home > .venues {
  position: relative;
  z-index: 1;
  top: -50px;
  margin: 0 auto;
  max-width: 990px;
}
.Home > .venues .Venue + .Venue {
  margin-top: 50px;
}
@media (max-width: 990px) {
  .Home > .venues {
    overflow-x: hidden;
  }
}
.MyTicketsDialog {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: hsla(0, 0%, 0%, 0.1);
  transition: opacity 0.1s cubic-bezier(0.23, 1, 0.32, 1);
}
.MyTicketsDialog > .close-button {
  position: absolute;
  left: 10px;
  top: 5px;
  display: none;
  padding: 15px;
  font-size: 24px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
@media (prefers-color-scheme: dark) {
  .MyTicketsDialog > .close-button {
    color: white;
  }
}
.MyTicketsDialog > .dialog {
  pointer-events: inherit;
  position: absolute;
  background: white;
  width: 220px;
  border-radius: 16px;
  transition: border-radius 0.1s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 8px 40px hsla(0, 0%, 0%, 0.2);
}
@keyframes ticket-dialog-bounce-in {
  from {
    transform: translateY(5px);
    box-shadow: 0 0 20px hsla(0, 0%, 100%, 0), 0 8px 40px hsla(0, 0%, 0%, 0);
  }
  20% {
    transform: translateY(10px);
    box-shadow: 0 0 20px hsla(0, 0%, 100%, 0.3), 0 8px 40px hsla(0, 0%, 0%, 0.1);
  }
  60% {
    transform: translateY(-2px);
    box-shadow: 0 0 20px hsla(0, 0%, 100%, 0.2), 0 8px 40px hsla(0, 0%, 0%, 0.2);
  }
  to {
    transform: translateY(0px);
    box-shadow: 0 0 20px hsla(0, 0%, 100%, 0), 0 8px 40px hsla(0, 0%, 0%, 0.2);
  }
}
.MyTicketsDialog > .dialog > .MyTicketsWidget {
  pointer-events: inherit;
  height: 40px;
}
.MyTicketsDialog > .dialog > .MyTicketsWidget,
.MyTicketsDialog > .dialog > .MyTicketsWidget:hover,
.MyTicketsDialog > .dialog > .MyTicketsWidget:active {
  background: none;
  color: inherit;
}
.MyTicketsDialog > .dialog > .notice {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  color: #999;
}
.MyTicketsDialog > .dialog .category {
  padding: 0 20px;
  margin-top: 20px;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: bold;
}
.MyTicketsDialog > .dialog > .button {
  cursor: pointer;
  width: 200px;
  padding: 10px 0;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 15px;
  background: #0784fa;
  border-radius: 10px;
  box-shadow: 0 6px 6px hsla(209, 96%, 50%, 0.4), inset 0 -3px 4px hsla(197, 99%, 63%, 0.4);
  color: white;
  text-align: center;
  font-weight: bold;
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.MyTicketsDialog > .dialog > .button:hover {
  background: #00aeff;
  box-shadow: 0 6px 6px hsla(194, 96%, 50%, 0.4), inset 0 -3px 4px hsla(197, 99%, 63%, 0.4);
}
.MyTicketsDialog > .dialog > .button:active {
  background: #045fd6;
  box-shadow: 0 6px 6px hsla(209, 99%, 46%, 0.4), inset 0 -3px 4px hsla(197, 99%, 63%, 0.6);
}
.MyTicketsDialog > .dialog .TicketItem {
  padding: 10px 20px;
}
.MyTicketsDialog > .dialog .TicketItem .label {
  color: #333;
  font-size: 15px;
  font-weight: 500;
}
.MyTicketsDialog > .dialog .TicketItem .quantity {
  font-size: 15px;
  color: #333;
}
.MyTicketsDialog > .dialog .TicketItem .quantity::before {
  content: "✕ ";
  font-size: 13px;
}
.MyTicketsDialog > .dialog .TicketItem + .TicketItem {
  border-top: 1px solid #eee;
}
.MyTicketsDialog:not(.open) {
  opacity: 0;
}
.MyTicketsDialog:not(.open) > .dialog {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.MyTicketsDialog.open {
  pointer-events: auto;
  opacity: 1;
}
.MyTicketsDialog.open > .close-button {
  transform: scale(1);
  transition-delay: 0.3s;
  opacity: 1;
}
.MyTicketsDialog.open > .dialog {
  animation: ticket-dialog-bounce-in 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
@media (max-width: 599px) {
  .MyTicketsDialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
  }
  .MyTicketsDialog .close-button {
    display: block;
  }
  .MyTicketsDialog > .dialog {
    position: static;
    margin-top: -40px;
  }
  .MyTicketsDialog.open > .dialog {
    animation: ticket-dialog-slide-in 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
}
@keyframes ticket-dialog-slide-in {
  from {
    transform: translateY(50vh);
  }
}
.MyTicketsWidget {
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 220px;
  border-radius: 16px;
  color: black;
  background: hsla(0, 0%, 95%, 0.15);
  transform: translate3d(0, 0, 0);
}
@media (prefers-color-scheme: dark) {
  .MyTicketsWidget {
    color: hsl(0, 0%, 97%);
    background: hsla(0, 0%, 0%, 0.25);
  }
}
.MyTicketsWidget > .icon {
  display: none;
}
.MyTicketsWidget > .label {
  pointer-events: none;
  text-align: center;
  position: relative;
  top: -1px;
}
.MyTicketsWidget > .ticket-count {
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -0.75em;
  min-width: 1.5em;
  height: 1.5em;
  padding: 3px;
  box-sizing: border-box;
  background: hsl(212, 100%, 50%);
  border-radius: 3em;
  font-weight: bold;
  line-height: 1.1em;
  text-align: center;
  color: white;
  box-shadow: 0 2px 4px hsla(212, 100%, 50%, 0.3);
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.MyTicketsWidget > .ticket-count::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.MyTicketsWidget > .ticket-count.visible {
  opacity: 1;
}
.MyTicketsWidget > .ticket-count.hidden {
  opacity: 0;
}
.MyTicketsWidget.pulse > .ticket-count {
  animation: ticket-count-pulse-gulp 0.9s cubic-bezier(0.23, 1, 0.32, 1) 0.1s;
}
.MyTicketsWidget.pulse > .ticket-count::after {
  animation-name: ticket-count-pulse-ring;
  animation-duration: 0.8s;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.MyTicketsWidget.pulse.outwards > .ticket-count {
  animation: ticket-count-pulse-shrink 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
.MyTicketsWidget.pulse.outwards > .ticket-count::after {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-direction: reverse;
}
.MyTicketsWidget.pulse.soft > .ticket-count {
  animation-duration: 0.8s;
  animation-delay: 0;
}
.MyTicketsWidget.pulse.soft > .ticket-count::after {
  animation: ticket-count-pulse-ring-soft 0.9s cubic-bezier(0.23, 1, 0.32, 1);
}
@keyframes ticket-count-pulse-gulp {
  from,
  to {
    transform: scale(1);
    filter: brightness(1);
  }
  20% {
    transform: scale(1.15);
    filter: brightness(1.5);
  }
}
@keyframes ticket-count-pulse-shrink {
  from,
  to {
    transform: scale(1);
    filter: brightness(1);
  }
  20% {
    transform: scale(0.9);
    filter: brightness(0.9);
  }
}
@keyframes ticket-count-pulse-ring {
  from {
    box-shadow: 0 0 0 50px hsla(212, 100%, 50%, 0);
  }
  to {
    box-shadow: 0 0 0 0 hsla(212, 100%, 50%, 0.5);
  }
}
@keyframes ticket-count-pulse-ring-soft {
  from {
    box-shadow: 0 0 0 0 hsla(212, 100%, 50%, 0.3);
  }
  to {
    box-shadow: 0 0 0 40px hsla(212, 100%, 50%, 0);
  }
}
.toolbar > .contents > .MyTicketsWidget {
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 4px 12px hsla(240, 4%, 5%, 0.1);
}
.toolbar > .contents > .MyTicketsWidget:hover {
  background: hsla(0, 0%, 100%, 0.4);
  box-shadow: inset 0 -5px 15px hsla(0, 0%, 100%, 0.3);
}
.toolbar > .contents > .MyTicketsWidget:active,
.toolbar > .contents > .MyTicketsWidget.selected {
  color: black;
  background: white;
  transition-duration: 0.1s;
}
.toolbar > .contents > .MyTicketsWidget:active .label,
.toolbar > .contents > .MyTicketsWidget.selected .label {
  opacity: 1;
}
@media (prefers-color-scheme: dark) {
  .toolbar > .contents > .MyTicketsWidget {
    box-shadow: 0 0 16px hsla(0, 0%, 100%, 0.1);
  }
  .toolbar > .contents > .MyTicketsWidget:hover {
    background: hsla(0, 0%, 0%, 0.3);
    box-shadow: inset 0 -5px 15px hsla(0, 0%, 0%, 0.3);
  }
  .toolbar > .contents > .MyTicketsWidget:active,
  .toolbar > .contents > .MyTicketsWidget.selected {
    color: #ffffff;
    background: hsla(0, 0%, 0%, 0.5);
  }
}
.Venue {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  position: relative;
  display: block;
  width: 990px;
  height: 500px;
  border-radius: 40px;
  overflow: hidden;
  font-family: 'Montserrat', 'Heiti SC', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  transition: transform 0.3s cubic-bezier(0.44, 2.16, 0.47, 0.74), filter 0.3s cubic-bezier(0.44, 2.16, 0.47, 0.74);
}
.Venue > .cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: inherit;
}
.Venue > .overlay {
  position: absolute;
  width: 970px;
  height: 150px;
  bottom: 10px;
  left: 10px;
  border-radius: 30px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.Venue > .overlay .frosted-glass {
  position: absolute;
  width: 990px;
  height: 500px;
  bottom: -10px;
  left: -10px;
  background-size: cover;
}
.Venue > .overlay > .description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 360px;
  top: 0;
  height: 150px;
  padding-right: 30px;
}
.Venue > .overlay > .description > .title {
  font-size: 40px;
}
.Venue > .overlay > .description > .subtitle {
  margin: 0.25em 0;
  font-size: 20px;
  opacity: 0.7;
}
.Venue > .preview {
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 300px;
  height: 240px;
  box-sizing: border-box;
  background-color: white;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 20px;
}
.Venue .cover,
.Venue .frosted-glass {
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.Venue:hover {
  transform: scale(1.01);
}
.Venue:hover .cover,
.Venue:hover .frosted-glass {
  transform: scale(1.05);
}
.Venue:active {
  filter: brightness(0.9);
  transform: scale(0.99);
}
@media (prefers-color-scheme: light) {
  .Venue {
    background-color: #eeeeee;
  }
  .Venue .overlay {
    background-color: #eeeeee;
  }
  .Venue .frosted-glass {
    filter: brightness(1.2) contrast(1.1);
    opacity: 0.5;
  }
  .Venue .description {
    color: hsla(0, 0%, 0%, 0.9);
  }
}
@media (prefers-color-scheme: dark) {
  .Venue {
    background-color: #333333;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1);
  }
  .Venue::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.1);
  }
  .Venue .overlay {
    background-color: #333333;
  }
  .Venue .frosted-glass {
    filter: brightness(0.7) contrast(1.2);
  }
  .Venue .description {
    color: hsla(0, 0%, 100%, 0.95);
  }
}
@media (max-width: 990px) {
  .Venue {
    border-radius: 0 !important;
    width: 100vw;
  }
  .Venue .overlay {
    width: calc(100vw - 10px * 2);
  }
  .Venue:active {
    transform: none;
  }
}
@media (max-width: 650px) {
  .Venue {
    height: 350px;
  }
  .Venue .preview {
    width: 140px;
    height: 110px;
  }
  .Venue > .overlay > .description {
    left: 190px;
  }
  .Venue > .overlay > .description > .title {
    font-size: 24px;
    font-weight: 600;
  }
  .Venue > .overlay > .description > .subtitle {
    font-size: 18px;
  }
}
.VenueView {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}
.VenueView > .frosted-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center 35%;
}
.VenueView > .top-panel {
  height: 200px;
  position: relative;
  z-index: 1;
}
.VenueView > .top-panel > .background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 200px;
  background-size: cover;
  background-position: center 35%;
}
.VenueView > .top-panel > .toolbar {
  cursor: default;
  pointer-events: none;
  position: absolute;
  border-radius: 100px;
  height: 40px;
  left: 10px;
  background: white;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1);
}
.VenueView > .top-panel > .toolbar > .content-clip {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  border-radius: inherit;
}
.VenueView > .top-panel > .toolbar::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.1);
}
.VenueView > .top-panel > .toolbar > .contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: inherit;
}
.VenueView > .top-panel > .toolbar > .contents .item {
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: inherit;
  padding: 10px 18px 10px 22px;
  box-sizing: border-box;
  color: hsla(0, 0%, 0%, 0.85);
}
.VenueView > .top-panel > .toolbar > .contents .item .icon {
  pointer-events: none;
  font-size: 26px;
  margin-right: 7px;
}
.VenueView > .top-panel > .toolbar > .contents .item .label {
  pointer-events: none;
  opacity: 0.7;
}
.VenueView > .top-panel > .toolbar > .contents .item.dropdown::after {
  position: relative;
  top: 2px;
  content: "\e946";
  font-family: 'seatsdesigner';
  margin-left: 8px;
}
.VenueView > .top-panel > .toolbar > .contents .item.combination > .labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-end;
}
.VenueView > .top-panel > .toolbar > .contents .item.combination > .labels > .label {
  font-size: 0.8em;
}
@media (prefers-color-scheme: dark) {
  .VenueView > .top-panel > .toolbar > .contents .item {
    color: hsla(0, 0%, 100%, 0.85);
  }
}
.VenueView > .top-panel > .toolbar > .contents > .MyTicketsWidget {
  height: calc(100% - 7px);
}
.VenueView > .top-panel > .toolbar > .contents.breadcrumbs .item .icon {
  padding-left: 5px;
  font-size: 36px;
}
.VenueView > .top-panel > .toolbar > .contents.breadcrumbs .item + .item::before {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  display: block;
  width: 15px;
  height: 40px;
  background-image: url("../assets/images/gui/breadcrumb-arrow-light@2x.png");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (prefers-color-scheme: dark) {
  .VenueView > .top-panel > .toolbar > .contents.breadcrumbs .item + .item::before {
    background-image: url("../assets/images/gui/breadcrumb-arrow-dark@2x.png");
  }
}
.VenueView > .top-panel > .toolbar > .contents.menus .item:hover {
  background: hsla(0, 0%, 100%, 0.1);
}
.VenueView > .top-panel > .toolbar > .contents.menus .item:active,
.VenueView > .top-panel > .toolbar > .contents.menus .item.selected {
  color: black;
  background: white;
}
.VenueView > .top-panel > .toolbar > .contents.menus .item:active .label,
.VenueView > .top-panel > .toolbar > .contents.menus .item.selected .label {
  opacity: 1;
}
@media (prefers-color-scheme: dark) {
  .VenueView > .top-panel > .toolbar > .contents.menus .item:hover {
    background: hsla(0, 0%, 0%, 0.1);
  }
  .VenueView > .top-panel > .toolbar > .contents.menus .item:active,
  .VenueView > .top-panel > .toolbar > .contents.menus .item.selected {
    color: #ffffff;
    background: hsla(0, 0%, 0%, 0.5);
  }
}
.VenueView > .top-panel > .toolbar > .contents.anchor-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 0;
}
.VenueView > .top-panel > .toolbar .frosted-glass {
  position: absolute;
  left: -10px;
  width: 100vw;
  height: 200px;
  background-size: cover;
  background-position: center 35%;
  filter: brightness(1.4) contrast(1.3);
  opacity: 0.3;
}
.VenueView > .top-panel > .toolbar.desktop-navigation {
  top: 10px;
}
.VenueView > .top-panel > .toolbar.desktop-navigation .frosted-glass {
  top: -10px;
}
.VenueView > .top-panel > .toolbar.mobile-navigation {
  left: 0;
  display: none;
  width: 100%;
}
.VenueView > .top-panel > .toolbar.mobile-navigation > .contents {
  justify-content: space-between;
}
.VenueView > .top-panel > .toolbar.mobile-navigation > .contents > .item > .icon {
  font-size: 32px;
}
.VenueView > .top-panel > .toolbar.bottom {
  bottom: 10px;
}
.VenueView > .top-panel > .toolbar.bottom .frosted-glass {
  bottom: -10px;
}
.VenueView > .top-panel > .toolbar.wide {
  width: 100%;
  left: 0;
  height: 44px;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 0;
}
.VenueView > .top-panel > .toolbar.wide.bottom {
  bottom: 0;
}
.VenueView > .top-panel > .toolbar.wide .frosted-glass {
  left: 0;
}
@media (prefers-color-scheme: dark) {
  .VenueView > .top-panel > .toolbar {
    background: black;
  }
  .VenueView > .top-panel > .toolbar .frosted-glass {
    filter: brightness(0.7) contrast(1.2);
    opacity: 1;
  }
}
.VenueView .chart {
  position: relative;
  height: calc(100vh - 200px - 64px);
}
.VenueView .chart .embed {
  position: relative;
  z-index: 1;
  height: inherit;
  background: #eee;
}
@media (prefers-color-scheme: dark) {
  .VenueView .chart .embed {
    background: #141414;
  }
}
.VenueView .chart .embed.light {
  background: #eee;
}
.VenueView .chart .embed.dark {
  background: #141414;
}
@media (min-width: 600px) {
  .VenueView > .top-panel .toolbar .label.short {
    display: none;
  }
}
@media (max-width: 599px) {
  .VenueView {
    background: white;
  }
  .VenueView .frosted-backdrop {
    filter: brightness(1.6) contrast(1.3);
    opacity: 0.3;
  }
  .VenueView > .top-panel {
    height: 100px;
    transition: 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    transition-property: opacity, transform;
  }
  .VenueView > .top-panel .background,
  .VenueView > .top-panel .frosted-glass {
    display: none;
  }
  .VenueView > .top-panel .toolbar {
    background: none;
    box-shadow: none;
  }
  .VenueView > .top-panel .toolbar .label.long {
    display: none;
  }
  .VenueView > .top-panel .toolbar.desktop-navigation,
  .VenueView > .top-panel .toolbar::after {
    display: none;
  }
  .VenueView > .top-panel .toolbar.mobile-navigation {
    display: block;
  }
  .VenueView > .top-panel .toolbar.bottom {
    height: auto;
    margin-bottom: 2px;
  }
  .VenueView > .top-panel .toolbar.bottom .contents {
    justify-content: flex-start;
  }
  .VenueView > .top-panel .toolbar.bottom .contents .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 0 4px;
    height: auto;
    width: 70px;
    border-radius: 8px;
  }
  .VenueView > .top-panel .toolbar.bottom .contents .item .icon {
    font-size: 26px;
    margin-right: 0;
    margin-bottom: 4px;
  }
  .VenueView > .top-panel .toolbar.bottom .contents .item .label {
    opacity: 0.7;
    font-size: 12px;
  }
  .VenueView > .top-panel .MyTicketsWidget {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column-reverse;
    padding: 4px 0 4px;
    height: auto;
    width: 80px;
    border-radius: 8px;
  }
  .VenueView > .top-panel .MyTicketsWidget > .icon {
    display: none;
    font-size: 26px;
    margin-right: 0;
    margin-bottom: 4px;
    visibility: hidden;
  }
  .VenueView > .top-panel .MyTicketsWidget > .ticket-count {
    position: static;
    margin: 3px 0;
  }
  .VenueView > .top-panel .MyTicketsWidget > .ticket-count.hidden {
    opacity: 0.9;
    background-color: hsl(0, 0%, 50%);
  }
  .VenueView > .top-panel .MyTicketsWidget > .label {
    opacity: 0.7;
    font-size: 12px;
  }
  .VenueView .chart .embed {
    height: 100%;
    transition: 1.2s cubic-bezier(0.23, 1, 0.32, 1);
    transition-property: opacity, transform, filter;
    transition-delay: 0.05s;
    border-radius: 6px;
    overflow: hidden;
    background: transparent;
  }
  .VenueView.openedTicketsDialog .top-panel {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
    transform: translateY(-100px);
    opacity: 0;
  }
  .VenueView.openedTicketsDialog .embed {
    transition-delay: 0;
    transform: scale(0.9);
    filter: blur(15px);
    opacity: 0.8;
  }
}
@media (max-width: 599px) and (prefers-color-scheme: dark) {
  .VenueView {
    background: black;
  }
  .VenueView .frosted-backdrop {
    filter: brightness(0.7) contrast(1.2);
    opacity: 1;
  }
}
.Button {
  position: relative;
  font-size: 14px;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  outline: none !important;
}
.Button .icon,
.Button .caption,
.Button img {
  pointer-events: none;
}
.Button.preset-contextual-menu-item {
  width: 100%;
  padding: 3px 20px 3px 16px;
  color: black;
  text-align: left;
}
@media (prefers-color-scheme: dark) {
  .Button.preset-contextual-menu-item {
    color: hsl(0, 0%, 97%);
  }
}
.Button.preset-contextual-menu-item > .wrapper {
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Button.preset-contextual-menu-item > .wrapper > .icon {
  display: block;
  margin-right: 8px;
  font-size: 19px;
}
.Button.preset-contextual-menu-item > .wrapper > .caption {
  display: block;
  font-size: 15px;
  padding-bottom: 1px;
  font-weight: 500;
}
@media (min-width: 600px) {
  .Button.preset-contextual-menu-item:hover:not(:disabled) {
    color: white;
    background: #0784fa;
  }
}
.Button.preset-contextual-menu-item:active:not(:disabled) {
  color: white;
  background: #005cc5;
}
.Button.preset-contextual-menu-item:disabled {
  color: grey;
}
.Button.preset-contextual-menu-item .wrapper.with-soft-icon {
  position: relative;
  padding-right: 2em;
}
.Button.preset-contextual-menu-item .wrapper.with-soft-icon .softIcon {
  position: absolute;
  right: -0.5em;
  opacity: 0.5;
  font-size: 0.9em;
}
.ContextualMenu {
  pointer-events: none;
  cursor: default;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: hsla(0, 0%, 0%, 0.1);
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
.ContextualMenu > .menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
  background: #fafafa;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 8px 22px rgba(0, 0, 0, 0.2), inset 0 1px 0 1px rgba(255, 255, 255, 0.25);
  animation: slight-zoom-in 0.1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (prefers-color-scheme: dark) {
  .ContextualMenu > .menu {
    background: hsla(0, 0%, 15%, 0.99);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 8px 22px rgba(20, 20, 20, 0.2), inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  }
}
.ContextualMenu > .menu > .title {
  pointer-events: none;
  width: 100%;
  height: 22px;
  box-sizing: border-box;
  color: #999;
  padding-left: 25px;
  padding-top: 3px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
.ContextualMenu > .menu > .separator {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ContextualMenu > .menu > .validation-item {
  padding: 5px 1.5em 5px 1em;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
}
.ContextualMenu > .menu > .validation-item.warning {
  color: #eca200;
}
.ContextualMenu > .menu > .validation-item.error {
  color: #db0e11;
}
.ContextualMenu > .menu > .validation-item > .icon {
  margin-right: 0.5em;
  font-size: 15px;
}
.ContextualMenu > .menu > .validation-item > .caption {
  position: relative;
  top: -1px;
}
.ContextualMenu.open {
  pointer-events: auto;
  opacity: 1;
}
@media (max-width: 599px) {
  .ContextualMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5px;
    padding-top: 105px;
    background-color: hsla(0, 0%, 0%, 0.5);
    transition-duration: 0.3s;
  }
  .ContextualMenu > .menu {
    min-width: 30vw;
    position: static;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .ContextualMenu > .menu .Button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 17px !important;
  }
  .ContextualMenu > .menu .Button .caption {
    font-size: inherit;
  }
  .ContextualMenu > .menu .Button .softIcon {
    font-size: inherit !important;
  }
  .ContextualMenu.open > .menu {
    animation: contextual-menu-mobile-in 0.3s cubic-bezier(0.23, 1, 0.32, 1) backwards;
    opacity: 1;
  }
}
@keyframes contextual-menu-in {
  from {
    background-color: hsla(0, 0%, 0%, 0);
  }
}
@keyframes contextual-menu-mobile-in {
  from {
    transform: translateY(-20px);
  }
}
.BestAvailableWidget {
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 220px;
  border-radius: 16px;
  color: black;
  background: hsla(0, 0%, 95%, 0.15);
  transform: translate3d(0, 0, 0);
  height: 2em;
}
@media (prefers-color-scheme: dark) {
  .BestAvailableWidget {
    color: hsl(0, 0%, 97%);
    background: hsla(0, 0%, 0%, 0.25);
  }
}
.BestAvailableWidget > .icon {
  display: none;
}
.BestAvailableWidget > .label {
  pointer-events: none;
  text-align: center;
  position: relative;
  top: -1px;
}
.toolbar > .contents > .BestAvailableWidget {
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 4px 12px hsla(240, 4%, 5%, 0.1);
}
.toolbar > .contents > .BestAvailableWidget:hover {
  background: hsla(0, 0%, 100%, 0.4);
  box-shadow: inset 0 -5px 15px hsla(0, 0%, 100%, 0.3);
}
.toolbar > .contents > .BestAvailableWidget:active,
.toolbar > .contents > .BestAvailableWidget.selected {
  color: black;
  background: white;
  transition-duration: 0.1s;
}
.toolbar > .contents > .BestAvailableWidget:active .label,
.toolbar > .contents > .BestAvailableWidget.selected .label {
  opacity: 1;
}
@media (prefers-color-scheme: dark) {
  .toolbar > .contents > .BestAvailableWidget {
    box-shadow: 0 0 16px hsla(0, 0%, 100%, 0.1);
  }
  .toolbar > .contents > .BestAvailableWidget:hover {
    background: hsla(0, 0%, 0%, 0.3);
    box-shadow: inset 0 -5px 15px hsla(0, 0%, 0%, 0.3);
  }
  .toolbar > .contents > .BestAvailableWidget:active,
  .toolbar > .contents > .BestAvailableWidget.selected {
    color: #ffffff;
    background: hsla(0, 0%, 0%, 0.5);
  }
}
.BestAvailableDialog {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: hsla(0, 0%, 0%, 0.1);
  transition: opacity 0.1s cubic-bezier(0.23, 1, 0.32, 1);
}
.BestAvailableDialog > .close-button {
  position: absolute;
  left: 10px;
  top: 5px;
  display: none;
  padding: 15px;
  font-size: 24px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
@media (prefers-color-scheme: dark) {
  .BestAvailableDialog > .close-button {
    color: white;
  }
}
.BestAvailableDialog > .dialog {
  pointer-events: inherit;
  position: absolute;
  background: white;
  width: 220px;
  border-radius: 16px;
  transition: border-radius 0.1s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 8px 40px hsla(0, 0%, 0%, 0.2);
  padding: 12px;
}
@keyframes ticket-dialog-bounce-in {
  from {
    transform: translateY(5px);
    box-shadow: 0 0 20px hsla(0, 0%, 100%, 0), 0 8px 40px hsla(0, 0%, 0%, 0);
  }
  20% {
    transform: translateY(10px);
    box-shadow: 0 0 20px hsla(0, 0%, 100%, 0.3), 0 8px 40px hsla(0, 0%, 0%, 0.1);
  }
  60% {
    transform: translateY(-2px);
    box-shadow: 0 0 20px hsla(0, 0%, 100%, 0.2), 0 8px 40px hsla(0, 0%, 0%, 0.2);
  }
  to {
    transform: translateY(0px);
    box-shadow: 0 0 20px hsla(0, 0%, 100%, 0), 0 8px 40px hsla(0, 0%, 0%, 0.2);
  }
}
.BestAvailableDialog > .dialog > .notice {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  color: #999;
}
.BestAvailableDialog > .dialog .category {
  padding: 0 20px;
  margin-top: 20px;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: bold;
}
.BestAvailableDialog > .dialog > .button {
  cursor: pointer;
  width: 200px;
  padding: 10px 0;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 15px;
  background: #0784fa;
  border-radius: 10px;
  box-shadow: 0 6px 6px hsla(209, 96%, 50%, 0.4), inset 0 -3px 4px hsla(197, 99%, 63%, 0.4);
  color: white;
  text-align: center;
  font-weight: bold;
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.BestAvailableDialog > .dialog > .button:hover {
  background: #00aeff;
  box-shadow: 0 6px 6px hsla(194, 96%, 50%, 0.4), inset 0 -3px 4px hsla(197, 99%, 63%, 0.4);
}
.BestAvailableDialog > .dialog > .button:active {
  background: #045fd6;
  box-shadow: 0 6px 6px hsla(209, 99%, 46%, 0.4), inset 0 -3px 4px hsla(197, 99%, 63%, 0.6);
}
.BestAvailableDialog > .dialog .Category {
  padding: 10px 20px;
}
.BestAvailableDialog > .dialog .Category .label {
  color: #333;
  font-size: 15px;
  font-weight: 500;
}
.BestAvailableDialog > .dialog .Category + .Category {
  border-top: 1px solid #eee;
}
.BestAvailableDialog:not(.open) {
  opacity: 0;
}
.BestAvailableDialog:not(.open) > .dialog {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.BestAvailableDialog.open {
  pointer-events: auto;
  opacity: 1;
}
.BestAvailableDialog.open > .close-button {
  transform: scale(1);
  transition-delay: 0.3s;
  opacity: 1;
}
.BestAvailableDialog.open > .dialog {
  animation: ticket-dialog-bounce-in 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
@media (max-width: 599px) {
  .BestAvailableDialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
  }
  .BestAvailableDialog .close-button {
    display: block;
  }
  .BestAvailableDialog > .dialog {
    position: static;
    margin-top: -40px;
  }
  .BestAvailableDialog.open > .dialog {
    animation: ticket-dialog-slide-in 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
}
@keyframes best-available-dialog-slide-in {
  from {
    transform: translateY(50vh);
  }
}
