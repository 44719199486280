.Venue {
    @overlay-spacing: 10px;
    @overlay-height: 150px;
    @container-width: 990px;
    @container-height: 500px;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    position: relative;
    display: block;
    width: @container-width;
    height: @container-height;
    border-radius: 40px;
    overflow: hidden;
    font-family: 'Montserrat', 'Heiti SC', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    transition:
        transform 0.3s @ease-long-rubber-band-in,
        filter 0.3s @ease-long-rubber-band-in;

    > .cover {
        .full-size-absolute;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: inherit;
    }

    > .overlay {
        position: absolute;
        width: 970px;
        height: @overlay-height;
        bottom: @overlay-spacing;
        left: @overlay-spacing;
        border-radius: 30px;
        overflow: hidden;
        transform: translate3d(0, 0, 0);

        .frosted-glass {
            position: absolute;
            width: @container-width;
            height: @container-height;
            bottom: -@overlay-spacing;
            left: -@overlay-spacing;
            background-size: cover;
        }

        > .description {
            .vertical-flex;
            justify-content: center;
            align-items: flex-start;
            position: absolute;
            left: 360px;
            top: 0;
            height: @overlay-height;
            padding-right: 30px;

            > .title {
                font-size: 40px;
            }
            > .subtitle {
                margin: 0.25em 0;
                font-size: 20px;
                opacity: 0.7;
            }
        }
    }

    > .preview {
        position: absolute;
        left: @overlay-spacing + 20px;
        bottom: @overlay-spacing + 20px;
        width: 300px;
        height: 240px;
        box-sizing: border-box;
        background-color: white;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 20px;
    }

    .cover,
    .frosted-glass {
        transition: transform 0.5s @ease-out-quint;
    }

    &:hover {
        transform: scale(1.01);
        .cover,
        .frosted-glass {
            transform: scale(1.05)
        }
    }

    &:active {
        filter: brightness(0.9);
        transform: scale(0.99);
    }

    @media (prefers-color-scheme: light) {
        background-color: #eeeeee;
        
        .overlay {
            background-color: #eeeeee;
        }
        .frosted-glass {
            filter: brightness(1.2) contrast(1.1);
            opacity: 0.5;
        }
        .description {
            color: hsla(0, 0%, 0%, 0.9);
        }
    }

    @media (prefers-color-scheme: dark) {
        background-color: #333333;
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1);

        &::after {
            content: "";
            display: block;
            .full-size-absolute;
            border-radius: inherit;
            box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.1);
        }
        .overlay {
            background-color: #333333;
        }
        .frosted-glass {
            filter: brightness(0.7) contrast(1.2);
        }
        .description {
            color: hsla(0, 0%, 100%, 0.95);
        }
    }

    @media (max-width: 990px) {
        border-radius: 0 !important;
        width: 100vw;

        .overlay {
            width: calc(100vw - @overlay-spacing * 2);
        }

        &:active {
            transform: none;
        }
    }

    @media (max-width: 650px) {
        height: 350px;

        .preview {
            width: 140px;
            height: 110px;
        }

        > .overlay > .description {
            left: 190px;

            > .title {
                font-size: 24px;
                font-weight: 600;
            }
            > .subtitle {
                font-size: 18px;
            }
        }
    }
}