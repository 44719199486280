.SceneTransition {
    pointer-events: none;
    .full-size-absolute;
    position: fixed;
    z-index: 1000;

    .sheet {
        .full-size-absolute;
        transform: translateX(100%);
        animation-duration: 1.5s;
        animation-timing-function: @ease-out-quint;

        &.foreground {
            background-color: rgb(130, 215, 230);
            animation-delay: 0.2s;
        }
        &.background {
            background-color: rgb(233, 128, 189);
        }
    }

    &.transitioning {
        pointer-events: auto;

        .sheet {
            animation-name: sheet-transition;
        }
    }

    @keyframes sheet-transition {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(-120%);
        }
    }
}